/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        
        // smooth scrool
        $('a[href*=#]:not([href=#])').click(function() {
          //if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
            
            var target = $(this.hash);
            var prettyLink = $(this).attr('href').substring(1);

            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            var topDistance = target.offset().top- 67;
            
            if($(this).parent('li').hasClass('expand')){
              var section_id = $(this).attr('href');
              if(!$(section_id + ' .collapsible').is(":visible")) {
                $(section_id + ' .collapsible-trigger').trigger('click');
              }
            }

            window.history.pushState(prettyLink, 'Biovital','/'+prettyLink+'/');
            $(document).prop('title',$(this).text()+' – Biovital – suplement diety');

            if (target.length) {
              $('html,body').animate({
                scrollTop: topDistance
              }, 1000);
              return false;
            }

          //}
        });

        function collapseElement(element) {
          if ($(element).hasClass('collapse-up')) {
            $(element).prev('.collapsible').slideToggle(); 
          } else {
            $(element).next('.collapsible').slideToggle();  
          }
          $(element).toggleClass('opened');

          if($(element).hasClass('opened') && $(element).hasClass('collapse-up')){
            $(element).text('Zobacz mniej');
          } else if(!$(element).hasClass('opened') && $(element).hasClass('collapse-up')){
            $(element).text('Zobacz więcej');
          }
        }

        //pretty links nav
        var prettyLinkNav = (function() {

          var pathname = window.location.pathname;
          var pathname_array = pathname.split('/');
          var mq = window.matchMedia( "(min-width: 768px)" );

          function getPathLength(pathname){ 
            var array = pathname.split('/');
            return array.length-2;
          }

          function goToSection(sectionName){
            var topDistance = $("#"+sectionName).offset().top - 67;

            collapseElement($("#"+sectionName+ ' .collapsible-trigger'));

            $('html,body').animate({
              scrollTop: topDistance
            }, 0);

          }


          switch(getPathLength(pathname)) {
            
            case 1: // archive or contact, get-advice section
              
              goToSection(pathname_array[1]);
              break;

            case 2: // single object      

              switch(pathname_array[1]) {

                case "produkty":
                  
                  $("#product-"+pathname_array[2]).css('display','block');//we need to show product

                  if (mq.matches) {  // on desktop
                    var productsAddCustomCss = function(){
                      $("#desktop-products").css('display','none');
                    };
                    $.when( productsAddCustomCss() ).then(function(){
                      goToSection(pathname_array[1]);
                    });
                  } else { // mobile
                    $("#carousel-products .carousel-item.active").removeClass('active');
                    $("#carousel-products li.active").removeClass('active');
                    $("#carousel-products")
                      .find("[data-product-id='" + pathname_array[2] + "']")
                      .addClass('active');
                    $("#carousel-products")
                      .find("[data-indicator-id='" + pathname_array[2] + "']")
                      .addClass('active');
                    goToSection(pathname_array[1]);
                  }
                break;

                case "artykuly":
                  var articlesAddCustomCss = function(){
                    $("#article-"+pathname_array[2]).css('display','block');
                  };
                  $.when( articlesAddCustomCss() ).then(function(){
                    goToSection("article-"+pathname_array[2]);
                  });
                break;

              }

              break;

          }


        })();

        // mobile menu
        $("#open-mobile-nav").click(function(event) {
          if($(this).hasClass('menu-opened')) {
            $("#nav-mobile").fadeOut();
            $(this).removeClass('menu-opened');
            $('body').removeClass('menu-opened');
          } else {
            $("#nav-mobile").fadeIn();
            $(this).addClass('menu-opened');
            $('body').addClass('menu-opened');
          }
        });

        $('#nav-mobile a').click(function(event) {
          $("#nav-mobile").fadeOut();
          $("#open-mobile-nav").removeClass('menu-opened');
          $('body').removeClass('menu-opened');
        });

        //collapsible elements
        $('.collapsible-trigger').click(function(event) {
          collapseElement($(this));
        });

        $('#carousel-articles').carousel({
          pause: "false"
        });
        //carousel pause
        $('#carousel-hero,#carousel-products').carousel({
            interval: false
        }); 

        //carousel swipe
        $('.carousel').swipe({
          swipe: function(event, direction, distance, duration, fingerCount, fingerData) {
            if (direction === 'left') {
              $(this).carousel('next');
            }
            if (direction === 'right') {
              $(this).carousel('prev');
            }
          },
          allowPageScroll:"vertical"
        });

        // pause video on lity lightbox open
        $(document).on('lity:open', function(event, instance) {
          $('#hero video').css('opacity','0');
        });    

        $(document).on('lity:close', function(event, instance) {
          $('#hero video').css('opacity','1');
        });

        
        /*----------  Products  ----------*/

        var mq = window.matchMedia( "(min-width: 768px)" );
        var animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';

        if (mq.matches) { // Desktop - window width is at least 768px

          $("#desktop-products .product-container").click(function(event) {
            var productID = $(this).data('product-id');
            window.history.pushState(productID, 'Biovital','/produkty/'+productID+'/');
            $("#desktop-products")
              .addClass('animated bounceOut')
              .one(animationEnd, function() {
                $(this).css('display','none');
                $(this).removeClass('animated bounceOut');
                $("#product-"+productID).css('display','block');
                $("#product-"+productID).addClass('animated bounceInUp').one(animationEnd, function() {
                  $(this).removeClass('animated bounceInUp');
                });
              });
          });
          
          $(".close-btn").click(function(event) {
            window.history.pushState('produkty', 'Biovital','/produkty/');
            var currentDescription = $(this).parent('.single-product-description');
              currentDescription.addClass('animated bounceOutDown')
              .one(animationEnd, function() {
                currentDescription.css('display','none');
                currentDescription.removeClass('animated bounceOutDown');
                $("#desktop-products").css('display','block');
                $("#desktop-products").addClass('animated fadeInUp').one(animationEnd, function() {
                  $(this).removeClass('animated fadeInUp');
                });
              });
          });
          
        } else { //  mobile width is less than 768px
          
          $("#carousel-products .btn").click(function(event) {
            event.preventDefault();
            var productID = $(this).data('product-id');
            window.history.pushState(productID, 'Biovital','/produkty/'+productID+'/');
            $('.single-product-description').css('display','none');
            $('#product-'+productID).css('display','block');
            var scrollDistance = $('#product-'+productID).offset().top - 67;
            $('html, body').animate({
              scrollTop: scrollDistance + 'px'
            }, 1000);
          });

          $(".close-btn").click(function(event) {
            window.history.pushState('produkty', 'Biovital','/produkty/');
            var currentDescription = $(this).parent('.single-product-description');
            currentDescription.fadeOut(2000, function() {});
            var scrollDistance = $('#carousel-products').offset().top - 67;
            $('html, body').animate({
              scrollTop: scrollDistance + 'px'
            }, 1000);
          });

          //change products on swipe
          $('#carousel-products').bind('slid.bs.carousel', function (e) {
            if($('.single-product-description').is(':visible')){
              var productID = $('#carousel-products .carousel-item.active').data('product-id');
              window.history.pushState(productID, 'Biovital','/produkty/'+productID+'/');
              $('.single-product-description').css('display','none');
              $('#product-'+productID).css('display','block');
            }

          });

        
        }

        $("#carousel-articles .btn").click(function(event) {
          event.preventDefault();
          var articleID = $(this).data('article-id');
          window.history.pushState(articleID, 'Artykuły Biovital','/artykuly/'+articleID+'/');
          $('.article-container').css('display','none');
          $('#article-'+articleID).css('display','block');
          var scrollDistance = $('#article-'+articleID).offset().top - 67;
          $('html, body').animate({
            scrollTop: scrollDistance + 'px'
          }, 1000);
        });

        $(".article-back").click(function(event) {
          event.preventDefault();
          window.history.pushState('artykuly', 'Artykuły Biovital','/artykuly/');
          var currentArticle = $(this).parent('.article-container');
          var scrollDistance = $('#carousel-articles').offset().top - 67;
          $('html, body').animate({
            scrollTop: scrollDistance + 'px'
          }, 1000);
          currentArticle.slideToggle(2000, function() {});
        });

        //Stylized checkboxes
        $('input').iCheck();

        //Survey navigation
        $("#otrzymaj-porade .begin-survey").click(function(event) {
          event.preventDefault();
          $("#otrzymaj-porade .survey-introduction").css('display','none'); 
          $("#otrzymaj-porade .step.step-1").css('display','block');
          $("#otrzymaj-porade .navigation").css('display','block'); 
          $(".survey-indicator-1").addClass('active');
        });


        function gotoNextSlide(nextElement){
          $('.step').css('display','none');
          $('.step.step-'+nextElement).fadeIn();
          
          $(".steps-nav-container li").removeClass('active');  
          $(".survey-indicator-"+nextElement).addClass('active');
        }

        function validateStep(currentElement){
          if ($('.step.step-'+currentElement).hasClass('no-validate')) {
            return true;
          } else if($('.step.step-'+currentElement).hasClass('step-last')){
            var checkbox = $('.step.step-'+currentElement+' input[type=checkbox]:checked');
            var mail = $('.step.step-'+currentElement+' #your-email').val();
            var name = $('.step.step-'+currentElement+' #your-name').val();
            if(checkbox.length > 0 && mail.length > 0 && name.length > 0) {
              return true;
            }
          } else {
            var radio = $('.step.step-'+currentElement+' input[type=radio]:checked');
            if(radio.length > 0) {
              return true;
            }
          }
        }


        $("#otrzymaj-porade .btn-nav").click(function(event) {
          
          event.preventDefault();
          var nextElement = $(this).attr('data-step');
          var numberOfSteps = 14;

          // Next step
          if($(this).hasClass('btn-next') && Number(nextElement) <= numberOfSteps ) {

            if(validateStep(Number(nextElement)-1)) {

              gotoNextSlide(nextElement);
              $('.btn-prev').attr('data-step', Number(nextElement) - 1) ;
              $(this).attr('data-step',Number(nextElement)+1);
            
            }

          // Previous step
          } else if ($(this).hasClass('btn-prev') && Number(nextElement) > 0) {
            
            gotoNextSlide(nextElement);
            $('.btn-next').attr('data-step', Number(nextElement) + 1) ;
            $(this).attr('data-step',Number(nextElement)-1);
          
          // Next step (last) - form is sent 
          } else if ($(this).hasClass('btn-next') && Number(nextElement) > numberOfSteps) {

            if(validateStep(Number(nextElement)-1)) {
            
              gotoNextSlide(nextElement);
              $('#otrzymaj-porade .wpcf7-submit').trigger('click');
              $('.btn-prev').attr('data-step', Number(nextElement) - 1) ;
              $(this).attr('data-step',Number(nextElement)+1);

            }
          
          // Previous step - go to introduction
          } else if ($(this).hasClass('btn-prev') && Number(nextElement) == 0) {
          
            gotoNextSlide(nextElement);
            $("#otrzymaj-porade .navigation").css('display','none'); 
            $("#otrzymaj-porade .survey-introduction").css('display','block'); 
          
          
          }

          // Previous step - from confirmation to the las step
          if ($(this).hasClass('btn-prev') && Number(nextElement) == numberOfSteps) {
            $('#otrzymaj-porade .wpcf7-form').removeClass('sent')
            // Removing cf7 notices when going back
            $('.wpcf7-response-output').css('display','none');
          }



        });



      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
